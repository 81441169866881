/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

export const onInitialClientRender = () => {
  const preconnect1 = document.createElement("link")
  preconnect1.rel = "preconnect"
  preconnect1.href = "https://fonts.googleapis.com"
  document.head.appendChild(preconnect1)

  const preconnect2 = document.createElement("link")
  preconnect2.rel = "preconnect"
  preconnect2.href = "https://fonts.gstatic.com"
  preconnect2.crossOrigin = "crossorigin"
  document.head.appendChild(preconnect2)

  const link = document.createElement("link")
  link.href =
    "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
  link.rel = "stylesheet"
  document.head.appendChild(link)
}

export const onClientEntry = () => {
  const script = document.createElement("script")
  script.type = "module"
  script.src = "https://unpkg.com/ionicons@5.4.0/dist/ionicons/ionicons.esm.js"
  document.body.appendChild(script)
}
